import React, { useEffect } from 'react'
import useBundle from '~/hooks/graphql/queries/use-bundle'
import useProducts from '~/hooks/graphql/queries/use-products'

const BazaarVoiceOrderConfirmationScripts = ({
  cart,
  isLoading,
  orderNumber,
}) => {
  const {
    total,
    currency,
    lineItems,
    email,
    locale,
    taxTotal,
    shipTotal,
    shipAddress,
  } = cart

  const products = useProducts()
  const bundles = useBundle() // if not slug is provided all bunndles will be returned

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (cart && !isLoading && bundles && products) {
        const formattedLineitems = lineItems
          .map(item => {
            const contentfulItemList = item?.isBundle ? bundles : products
            const contentfulItem = contentfulItemList?.find(
              b => b?.slug === item?.variant?.slug,
            )
            const reviewProductId = contentfulItem?.yotpoId
            const discount = item.adjustments?.reduce((a, c) => {
              const discount = Number(c.amount)
              return a + Math.abs(discount < 0 ? discount : 0)
            }, 0)

            return {
              price: item.price, // all floats need to be strings
              quantity: item.quantity, // all ints need to be strings
              productId: reviewProductId, // should match the product cataloue ID
              name: item.variant.name,
              discount: discount.toFixed(2),
              isGoods: contentfulItem.isGoods,
            }
          })
          .filter(item => {
            return !item.isGoods
          })

        if (formattedLineitems) {
          window.bvCallback = function (BV) {
            BV.pixel.trackTransaction({
              currency: currency,
              orderId: orderNumber,
              total: `${Number(total) - Number(shipTotal) - Number(taxTotal)}`, // subtotal WITHOUT tax, shipping, discounts
              tax: taxTotal,
              shipping: shipTotal,
              items: formattedLineitems,
              email: email,
              locale: locale.replace(/-/g, '_'),
              nickname: shipAddress.name.split(' ')[0], // First name. Only used for email personalization
            })
          }
        }
      }
    }
  }, [cart, isLoading, orderNumber, total, currency, bundles, products])

  return null
}

export default BazaarVoiceOrderConfirmationScripts
